import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {
  translationsFactory,
  TranslationsContext,
  Translation,
  Letters,
  fallbackAlphabet,
  useTranslations,
} from 'settings/i18n';
import { useIsRtl } from 'hooks/useCurrentLocale';
import useLinkCreator from 'hooks/useLinkCreator';
import EducationNavigation from 'components/EducationNavigation';
import Footer from 'components/Footer';
import SEO, { SEOMeta } from 'components/SEO/SEO';
import Breadcrumbs from 'components/Breadcrumbs';
import SocialShareBar from 'components/SocialShareBar';
import { EducationHeading } from 'components/EducationTile/EducationTile';
import Heading from 'components/Heading/Heading';
import Text from 'components/Text/Text';
import whatIsMining from 'images/what-is-mining.svg';
import whatIsMergedMining from 'images/what-is-merged-mining.svg';
import mathBehindMining from 'images/math-behind-mining.svg';
import miningPools from 'images/mining-pools.svg';
import {
  Main,
  Wrapper,
  Trapezoid,
  TrapezoidWrapper,
  Line,
  Excerpt,
  CryptocurrencyHeading,
  ActionButtons,
  ActionButton,
  Image,
  RelativeContainer,
  ReversedLine,
  ReversedImage,
  ReversedExcerpt,
  ReversedTrapezoid,
  ReversedOrnamentTrapezoid,
  ThirdSection,
  PdfActionButton,
} from 'components/EducationSection/EducationSection';
import EducationGlossary from 'components/EducationGlossary/EducationGlossary';
import ClientOnly from 'components/ClientOnly/ClientOnly';
import useResponsivePdfLink, { PdfLinks } from 'hooks/useResponsivePdfLink';
import extractPdfUrlsFactory from 'settings/extract-pdf-urls';
import menuLinks from 'settings/menuLinks';

const EducationMiningMain = styled(Main)`
  overflow: hidden;
`;

const EducationMiningProviders = ({
  data,
}: {
  data: {
    translations: Translation;
    letters: Letters;
    education: Translation;
    homepage: Translation;
    metadata: SEOMeta;
  };
}) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data?.translations?.translation,
        ...data?.education?.translation,
        ...data?.homepage?.translation,
      },
    },
  });

  const extractPdfUrls = extractPdfUrlsFactory(data);
  const letters = data?.letters?.translation.letters || fallbackAlphabet;

  return (
    <TranslationsContext.Provider value={t}>
      <SEO metadata={data.metadata || undefined} url={menuLinks.mining} />
      <ClientOnly>
        <SocialShareBar pageTitle={data?.metadata?.seo?.title} />
        <EducationNavigation />
        <EducationMining
          letters={letters}
          pdfs={{
            mining: extractPdfUrls('whatIsMining', {
              fallbackDesktop: 'mining-desktop_en',
              fallbackMobile: 'mining-mobile_en',
            }),
            mergedMining: extractPdfUrls('whatIsMergedMining', {
              fallbackDesktop: 'merged-mining-desktop_en',
              fallbackMobile: 'merged-mining-mobile_en',
            }),
            mathBehindMining: extractPdfUrls('mathBehindMining', {
              fallbackDesktop: 'math-behind-mining-desktop_en',
              fallbackMobile: 'math-behind-mining-mobile_en',
            }),
            miningPools: extractPdfUrls('miningPools', {
              fallbackDesktop: 'mining-pools-desktop_en',
              fallbackMobile: 'mining-pools-mobile_en',
            }),
          }}
        />
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

const EducationMining = ({
  letters,
  pdfs,
}: {
  letters: Letters['translation']['letters'];
  pdfs: {
    mining: PdfLinks;
    mergedMining: PdfLinks;
    mathBehindMining: PdfLinks;
    miningPools: PdfLinks;
  };
}) => {
  const t = useTranslations();
  const createLink = useLinkCreator();
  const isRtl = useIsRtl();

  const miningPdfLink = useResponsivePdfLink(pdfs.mining);
  const mergedMiningPdfLink = useResponsivePdfLink(pdfs.mergedMining);
  const mathBehindMiningPdfLink = useResponsivePdfLink(pdfs.mathBehindMining);
  const miningPoolsPdfLink = useResponsivePdfLink(pdfs.miningPools);

  return (
    <EducationMiningMain>
      <Wrapper>
        <EducationHeading
          isRtl={isRtl}
          size="heading"
          color="dark"
          weight="medium"
          letterSpacing="tight">
          {t('mining')}
        </EducationHeading>

        <Breadcrumbs
          links={[
            { to: createLink(menuLinks.home), name: t('home') },
            { to: createLink(menuLinks.education), name: t('education') },
            { to: createLink(menuLinks.mining), name: t('mining') },
          ]}
        />
      </Wrapper>

      <RelativeContainer isRtl={isRtl}>
        <Trapezoid>
          <Line>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal">
              1
            </Heading>
          </Line>
          <TrapezoidWrapper>
            <Excerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('workingForYourCrypto')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('workingForYourCryptoContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={miningPdfLink} />
                <ActionButton to={createLink(menuLinks.quizMining)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </Excerpt>
            <Image src={whatIsMining} alt={t('workingForYourCrypto')} />
          </TrapezoidWrapper>
        </Trapezoid>

        <ReversedTrapezoid>
          <ReversedLine style={{ marginLeft: '0' }}>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal"
              style={{ background: '#e9f6ff' }}>
              2
            </Heading>
          </ReversedLine>
          <TrapezoidWrapper>
            <ReversedImage
              style={{ transform: 'scale(1.4)' }}
              src={whatIsMergedMining}
              alt={t('whatIsTestnet')}
            />
            <ReversedExcerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('whatIsMergedMining')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('whatIsMergedMiningContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={mergedMiningPdfLink} />
              </ActionButtons>
            </ReversedExcerpt>
          </TrapezoidWrapper>
        </ReversedTrapezoid>

        <ThirdSection>
          <Line>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal">
              3
            </Heading>
          </Line>
          <TrapezoidWrapper>
            <Excerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('mathBehindMining')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('mathBehindMiningContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={mathBehindMiningPdfLink} />
                <ActionButton to={createLink(menuLinks.quizMathBehindMining)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </Excerpt>
            <Image
              style={{ transform: 'scale(1.4)' }}
              src={mathBehindMining}
              alt={t('mathBehindMining')}
            />
          </TrapezoidWrapper>
        </ThirdSection>

        <ReversedTrapezoid>
          <ReversedLine style={{ marginLeft: '0' }}>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal"
              style={{ background: '#e9f6ff' }}>
              4
            </Heading>
          </ReversedLine>
          <TrapezoidWrapper>
            <ReversedImage
              src={miningPools}
              alt={t('miningPools')}
              style={{ transform: 'scale(1.2)' }}
            />
            <ReversedExcerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('miningPools')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('miningPoolsContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={miningPoolsPdfLink} />
                <ActionButton to={createLink(menuLinks.quizMiningPools)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </ReversedExcerpt>
          </TrapezoidWrapper>
        </ReversedTrapezoid>

        <ReversedOrnamentTrapezoid />
      </RelativeContainer>

      <EducationGlossary letters={letters} />
    </EducationMiningMain>
  );
};

export default EducationMiningProviders;

export const query = graphql`
  query($locale: String!) {
    translations: wpPage(
      title: { in: "glossary" }
      language: { locale: { eq: $locale } }
    ) {
      ...GlossaryTranslation
    }
    
    metadata: wpPage(
      slug: { regex: "/\\b(\\w*education-mining\\w*)+(-\\d+)?\\b$/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }

    letters: wpPage(
      title: { in: "alphabet" }
      language: { locale: { eq: $locale } }
    ) {
      translation {
        letters
      }
    }

    education: wpPage(
      slug: { regex: "/\\b(\\w*education\\w*)+(-\\d+)?\\b$/" }
      language: { locale: { eq: $locale } }
    ) {
      translation {
        education
        home
        mining
        pdf
        video
        quiz
        workingForYourCrypto
        workingForYourCryptoContent
        whatIsMergedMining
        whatIsMergedMiningContent
        mathBehindMining
        mathBehindMiningContent
        miningPools
        miningPoolsContent
        glossary
        whatIsMiningPdfDesktop {
          slug
        }
        whatIsMiningPdfMobile {
          slug
        }
        whatIsMergedMiningPdfDesktop {
          slug
        }
        whatIsMergedMiningPdfMobile {
          slug
        }
        mathBehindMiningPdfDesktop {
          slug
        }
        mathBehindMiningPdfMobile {
          slug
        }
        miningPoolsPdfDesktop {
          slug
        }
        miningPoolsPdfMobile {
          slug
        }
      }
    }
    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
    }
  }
`;
